$(function() {
    if (typeof meta !== 'undefined') {
        var names = new Array();
        var model = new Array();

        $.each(meta.grid.fields, function(i, val) {
            names[i] = val.name;
            model[i] = {
                name: val.id,
                index: val.id,
                width: val.width,
                align: val.align,
                sortable: val.sortable,
                search: val.search,
                stype: val.stype,
                searchoptions: {
                    value: val.searchoptions,
                    dataInit: val.wheretype == 'date' ? dateFilter : undefined
                },
                formatter: val.formatter,
                formatoptions: {
                    srcformat: val.format,
                    newformat: val.format
                }
            };
        });

        $.extend($.fn.fmatter, {
            date: function(cellValue, options, rowData) {
                return cellValue != '01/01/70' ? cellValue : '';
            }
        });
        $.extend($.fn.fmatter, {
            formatColor: function(cellValue, options, rowData) {
                return '<span class="label label-' + cellValue + '" title="cellValue">&nbsp;</span>';
            }
        });
        $.extend($.fn.fmatter.formatColor, {
            unformat : function(cellvalue, options, cellObject) {
                return $(cellObject.html()).attr('title');
            }
        });

        $('#grid').jqGrid({
            url: path + '/api/grid/' + meta.section + '/',
            datatype: 'json',
            colNames: names,
            colModel: model,
            pager: '#toolbar',
            rowNum: rowNum,
            rowList: (level > 2) ? [10, 15, 20, 30, 50] : [10, 15, 20],
            height: '100%',
            sortname: meta.sortname,
            viewrecords: true,
            sortorder: meta.sortorder,
            caption: meta.caption,
            viewsortcols: [true, 'vertical', true],
            gridview: true,
            styleUI : 'Bootstrap5',
            iconSet: 'fontAwesome',
            autowidth: true,
            responsive : meta.responsive,
            jsonReader: {
                repeatitems: false,
                id: meta.key
            },
            ondblClickRow: function(id) {
                window.location = path + '/admin/s/' + meta.section + '/details/' + id + '/';
            }
        });
        $('#grid').jqGrid('navGrid', '#toolbar', {
            del: false,
            add: false,
            edit: false,
            search: false,
            refresh: false
        });
        $('#grid').jqGrid('filterToolbar', {
            stringResult: true,
            searchOnEnter: false
        });
        $('#grid').jqGrid('navButtonAdd', '#toolbar', {
            caption: 'Recargar',
            buttonicon: 'fa-sync',
            onClickButton: function() {
                var grid = $('#grid');
                grid[0].clearToolbar();
            }
        });
        $('#grid').jqGrid('navButtonAdd', '#toolbar', {
            caption: 'Detalles',
            buttonicon: 'fa-eye',
            onClickButton: function() {
                var gsr = $('#grid').jqGrid('getGridParam', 'selrow');
                if (gsr) {
                    window.open(path + '/admin/s/' + meta.section + '/details/' + gsr + '/');
                } else {
                    alert('Selecciona un registro');
                }
            }
        });
        if (meta.edit) {
            $('#grid').jqGrid('navButtonAdd', '#toolbar', {
                caption: 'Editar',
                buttonicon: 'fa-edit',
                onClickButton: function() {
                    var gsr = $('#grid').jqGrid('getGridParam', 'selrow');
                    if (gsr) {
                        window.open(path + '/admin/s/' + meta.section + '/edit/' + gsr + '/');
                    } else {
                        alert('Selecciona un registro');
                    }
                }
            });
        }
        if (meta.add) {
            $('#grid').jqGrid('navButtonAdd', '#toolbar', {
                caption: 'Añadir',
                buttonicon: 'fa-plus',
                onClickButton: function() {
                    window.open(path + '/admin/s/' + meta.section + '/add/');
                }
            });
        }
        if (meta.export) {
            $.each(meta.export, function(key, val) {
                var caption = (val != '') ? 'Exportar ' + val : 'Exportar';
                $('#grid').jqGrid('navButtonAdd', '#toolbar', {
                    caption: caption,
                    buttonicon: 'fa-save',
                    onClickButton: function() {
                        $('#grid').jqGrid('setCaption', 'Cargando...');
                        var data = '';
                        var postData = $('#grid').jqGrid('getGridParam', 'postData');
                        for (var param in postData) {
                            data += param + '=' + postData[param] + '&';
                        }
                        $.ajax({
                            type: 'get',
                            url: path + '/api/grid/' + meta.section + '/' + key + '/',
                            data: data,
                            dataType: 'json',
                            success: function(data) {
                                $('#grid').jqGrid('setCaption', meta.caption);
                                window.location = path + '/export/?name=' + data.filename;
                            }
                        });
                    }
                });
            });
        }
    }
});

function dateFilter(el) {
    var fmt = 'DD/MM/YYYY';
    var sep = ' - ';

    $(el).daterangepicker({
        singleDatePicker: false,
        showDropdowns: true,
        autoUpdateInput: false,
        autoApply: true,
        locale: {
            format: fmt,
            separator: sep
        }
    }, function (start, end, label) {
        $(el).val(start.format(fmt) + sep + end.format(fmt));
        $(el).trigger({
            type: 'keydown',
            which: 50
        });
    });
}
