$(function() {
    if (typeof form !== 'undefined') {
        $('.custom-file-input').on('change', function (e) {
            var names = [];
            $.each(e.target.files, function (i, file) {
                names.push(file.name);
            });

            var name = e.target.files[0].name;
            $(this).next('.custom-file-label').html(names.join(', ')).addClass('selected');
        });

        $('[data-toggle="datepicker"]').each(function (i, el) {
            var fmt = 'DD/MM/YYYY';
            var sep = ' - ';

            var range = false;
            if (typeof $(el).data('range') === 'boolean') {
                range = $(el).data('range');
            }

            $(el).daterangepicker({
                singleDatePicker: !range,
                showDropdowns: true,
                autoUpdateInput: false,
                autoApply: true,
                locale: {
                    format: fmt,
                    separator: sep
                }
            }, function (start, end, label) {
                if (range) {
                    $(el).val(start.format(fmt) + sep + end.format(fmt));
                } else {
                    $(el).val(start.format(fmt));
                }
            });
        });
        
        $('[data-toggle="select2"]').select2({});

        $('[data-toggle="validate"]').validate({
            highlight: function (element, errorClass, validClass) {
                if ($(element).hasClass('select2-hidden-accessible')) {
                    $(element).next('.select2-container').addClass(errorClass).removeClass(validClass);
                } else if ($(element).parent('.custom-file').length) {
                    $(element).parent('.custom-file').addClass(errorClass).removeClass(validClass);
                } else {
                    $(element).addClass(errorClass).removeClass(validClass);
                }
            },
            unhighlight: function (element, errorClass, validClass) {
                if ($(element).hasClass('select2-hidden-accessible')) {
                    $(element).next('.select2-container').removeClass(errorClass).addClass(validClass);
                } else if ($(element).parent('.custom-file').length) {
                    $(element).parent('.custom-file').removeClass(errorClass).addClass(validClass);
                } else {
                    $(element).removeClass(errorClass).addClass(validClass);
                }
            },
            errorPlacement: function (error, element) {}
        });

        $('.select2-hidden-accessible').on('change', function () {
            if ($(this).valid()) {
                $(this).next('.select2-container').removeClass('error').addClass('valid');
            }
        });

        $('[data-toggle="summernote"]').summernote({
            lang: 'es-ES',
            height: 140,
            toolbar: [
                ['style', ['style']],
                ['font', ['bold', 'italic', 'underline', 'strikethrough', 'clear']],
                ['color', ['color']],
                ['insert', ['link', 'table']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['view', ['undo', 'redo', 'fullscreen', 'codeview']]
            ],
            callbacks: {
                // fix broken checkbox on link modal
                onInit: function onInit(e) {
                    var editor = $(e.editor);
                    editor.find('.custom-control-description').addClass('custom-control-label').parent().removeAttr('for');
                }
            }
        });

        $('#fine-uploader-s3').fineUploaderS3({
            template: 'qq-template-s3',
            request: {
                endpoint: 'https://' + form.params.s3_bucket + '.s3.amazonaws.com',
                accessKey: form.params.aws_key
            },
            signature: {
                endpoint: path + '/api/uploader/signature/'
            },
            uploadSuccess: {
                endpoint: path + '/api/uploader/success/'
            },
            deleteFile: {
                enabled: true,
                method: 'POST',
                endpoint: path + '/api/uploader/delete/'
            },
            cors: {
                expected: true
            },
            chunking: {
                enabled: true,
                concurrent: {
                    enabled: true
                }
            },
            resume: {
                enabled: true
            },
            objectProperties: {
                acl: 'public-read',
                key: function(id) {
                    var uuid = this.getUuid(id);
                    var name = this.getName(id);
                    var ext = name.substr(name.lastIndexOf('.') + 1);

                    return form.params.s3_path + '/' + uuid + '.' + ext;
                }
            },
            thumbnails: {
                placeholders: {
                    notAvailablePath: path + '/dist/img/uploader/not_available-generic.png',
                    waitingPath: path + '/dist/img/uploader/waiting-generic.png'
                }
            },
            callbacks: {
                onSubmit: function (id, name) {
                    var data = {};
                    $('#data-form').serializeArray().map(function(x) {
                        data[x.name] = x.value;
                    });

                    this.setUploadSuccessParams({'data': data}, id);
                },
                onComplete: function (id, name, response) {
                    var input = $('[data-input="uploader"]');
                    var assetIds = [];
                    if (input.val().length > 0) {
                        assetIds = input.val().split(',');
                    }
                    assetIds.push(response.asset_id);
                    input.val(assetIds.join(','));

                    var previewLink = qq(this.getItemByFileId(id)).getByClass('preview-link')[0];
                    if (response.success) {
                        previewLink.setAttribute('href', response.link)
                    }

                    this.setDeleteFileParams({asset_id: response.asset_id}, id);
                },
                onDeleteComplete(id, xhr) {
                    var response = $.parseJSON(xhr.response);

                    var input = $('[data-input="uploader"]');
                    var assetIds = [];
                    if (input.val().length > 0) {
                        assetIds = input.val().split(',');
                    }
                    assetIds = assetIds.filter(function(i) {
                        return i != response.asset_id; 
                    });
                    input.val(assetIds.join(','));
                }
            }
        });

        $.each(form.events, function (key, event) {
            setEventListener(key, event);
        });
    }
});

function setEventListener(key, event) {
    var el = $('#' + key);

    if (event.name == 'search' && el.hasClass('select2-hidden-accessible')) {
        el.select2({
            language: 'es',
            minimumInputLength: 3,
            ajax: {
                delay: 250,
                url: path + '/api/form/' + nav + '/' + event.action + '/',
                data: function (params) {
                    return {
                        term: params.term
                    }
                },
                dataType: 'json',
                processResults: function (data) {
                    var options = [{
                        id: '',
                        text: '-'
                    }];

                    $.each(data.result, function (id, text) {
                        options.push({
                            id: id,
                            text: text
                        });
                    });

                    return {
                        results: options,
                        pagination: {
                            more: false
                        }
                    };
                }
            }
        });
    } else {
        el.on(event.name, function(e) {
            var el = $('#' + event.target);
            var value = $(this).val();

            if (value == '') {
                clearEventField(el);
            }

            $.ajax({
                type: 'get',
                url: path + '/api/form/' + nav + '/' + event.action + '/',
                data: key + '=' + value,
                dataType: 'json',
                success: function(data) {
                    if (data.type == 'events') {
                        $.each(data.result, function (key, event) {
                            setEventListener(key, event);
                        });
                    } else {
                        setEventField(el, data.result, data.type);
                    }
                    el.prop('disabled', false);
                },
                beforeSend: function() {
                    clearEventField(el);
                    el.prop('disabled', true);
                },
                error: function() {
                    el.prop('disabled', false);
                }
            });
        });
    }

    form.events[key] = event;
}

function clearEventField(el) {
    var key = el.attr('id');
    var event = form.events[key];

    if (el.hasClass('select2-hidden-accessible')) {
        el.val(null).html('').select2({
            data: [{
                id: '',
                text: '-'
            }]
        }).trigger('change');
    } else {
        el.val('').trigger('change');
    }

    el.off();

    delete form.events[key];
}

function setEventField(el, result, type) {
    var result = (result != null) ? result : '';
    var type = (type != null) ? type : 'value';

    if (type == 'options' && el.hasClass('select2-hidden-accessible')) {
        var options = [{
            id: '',
            text: '-'
        }];

        $.each(result, function (id, text) {
            options.push({
                id: id,
                text: text
            });
        });

        el.val(null).html('').select2({
            data: options
        }).trigger('change');
    } else if (type == 'value') {
        el.val(result).trigger('change');
    }
}

function doSubmit(id, showAlert) {
    if (showAlert) {
        if (confirm('Are you sure? Confirm!')) {
            $('#' + id).submit();
            
            return true;
        }
    } else {
        $('#' + id).submit();
        
        return true;
    }
}

function adminAction(action, conf) {
    var conf = (conf != null) ? conf : true;
    
    var result = (conf) ? confirm('Are you sure? Confirm!') : true;
    
    if (result) {
        $('#f_' + action).submit();
        
        return true;
    }

    return false;
}

function adminActionData(action, name, def) {
    var name = (name != null) ? name : 'Data';
    var def = (def != null) ? def : '';
    
    var data = prompt('Enter ' + name, def);

    if (data !== null) {
        $('#d_' + action).val(data);
        $('#f_' + action).submit();
        
        return true;
    }

    return false;
}
