$(function() {
    if (typeof awsName !== 'undefined' && awsName != '') {
        setAwsTimer();

        $('#job-btn').on('click', function (e) {
            var action = 'run';
            var job = $('#job-name').val();
            var params = $('#job-params').val();

            if (job != '') {
                var action = 'run/' + job;
                runAwsAction(action, params);
            } else {
                setAwsActionMessage('Action "' + action + '" failed: Invalid Job', 'danger');
            }
        });

        $('#queue-btn').on('click', function (e) {
            var action = 'queue';
            var msg = $('#queue-msg').val();

            if (msg != '') {
                var params = 'msg=' + msg
                runAwsAction(action, params);
            } else {
                setAwsActionMessage('Action "' + action + '" failed: Invalid Job', 'danger');
            }
        });
    }
});

function runAwsAction(action, params = '') {
    $.ajax({
        type: 'get',
        url: path + '/api/aws/' + awsName + '/' + action + '/',
        data: params,
        dataType: 'json',
        success: function(data) {
            if (data.status == 'error') {
                setAwsActionMessage('Action "' + action + '" failed: API Error', 'danger');
            } else {
                setAwsStatus(data.status);
                setAwsActionMessage('Action "' + action + '" executed!', 'success');
            }
            setAwsLoader(0);
            setAwsTimer();
            $('#job-btn').prop('disabled', false);
        },
        beforeSend: function() {
            setAwsActionMessage();
            setAwsLoader(1);
            $('#job-btn').prop('disabled', true);
        },
        error: function () {
            setAwsActionMessage('Action "' + action + '" failed: API Error', 'danger');
            setAwsLoader(0);
            setAwsTimer();
            $('#job-btn').prop('disabled', false);
        }
    });
}

function reloadAwsStatus() {
    $.ajax({
        type: 'get',
        url: path + '/api/aws/' + awsName + '/status/',
        dataType: 'json',
        success: function(data) {
            if (data.status == 'error') {
                setAwsStatus('unknown');
            } else {
                setAwsStatus(data.status);
            }
            setAwsLoader(0);
            setAwsTimer();
        },
        beforeSend: function() {
            setAwsActionMessage();
            setAwsLoader(1);
        },
        error: function () {
            setAwsStatus('unknown');
            setAwsLoader(0);
            setAwsTimer();
        }
    });
}

function setAwsTimer() {
    clearInterval(awsTimer);
    awsTimer = setInterval(reloadAwsStatus, 10000);
}

function setAwsLoader(show = 0) {
    if (show == 1) {
        $('#loader').html('<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>');
    } else {
        $('#loader').html('<i class="fas fa-sync-alt"></i>');
    }
}

function setAwsActionMessage(message = '', type = 'success') {
    if (message != '') {
        $('#message').html('<span class="badge badge-' + type + '">' + message + '</span>');
    } else {
        $('#message').html('');
    }
}

function setAwsStatus(status) {
    var html = '';

    if (status == 'unknown') {
        html += '<span class="badge badge-danger">Unknown</span>';
        $('#start').hide();
        $('#stop').hide();
    } else if (status == 'pending') {
        html += '<span class="badge badge-warning">Pending</span>';
        $('#start').hide();
        $('#stop').hide();
    } else if (status == 'initializing') {
        html += '<span class="badge badge-warning">Initializing</span>';
        $('#start').hide();
        $('#stop').show();
    } else if (status == 'running') {
        html += '<span class="badge badge-success">Running</span>';
        $('#start').hide();
        $('#stop').show();
    } else if (status == 'failed') {
        html += '<span class="badge badge-danger">Failed</span>';
        $('#start').hide();
        $('#stop').show();
    } else if (status == 'shutting') {
        html += '<span class="badge badge-warning">Shutting</span>';
        $('#start').hide();
        $('#stop').hide();
    } else if (status == 'terminated') {
        html += '<span class="badge badge-danger">Terminated</span>';
        $('#start').hide();
        $('#stop').hide();
    } else if (status == 'stopping') {
        html += '<span class="badge badge-warning">Stopping</span>';
        $('#start').hide();
        $('#stop').hide();
    } else if (status == 'stopped') {
        html += '<span class="badge badge-secondary">Stopped</span>';
        $('#start').show();
        $('#stop').hide();
    }

    $('#status').html(html);
}

function cleanForm(form) {
    if (form == 'job') {
        $('#job-name').val('');
        $('#job-params').val('');
    } else if (form == 'queue') {
        $('#queue-msg').val('');
    }
}
