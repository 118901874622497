var widgets = {};
var params = {};
var maps = {};
var textColor = '#6c757d';
var palette = {
    blue: '#1f77b4',
    green: '#2ca02c',
    orange: '#ff7f0e',
    red: '#d62728',
    purple: '#9467bd',
    yellow: '#bcbd22',
    brown: '#8c564b',
    pink: '#e377c2',
    cyan: '#17becf',
    grey: '#7f7f7f'
};
var number = {
    decimals: 2,
    dec_point: ',',
    thou_sep: '.'
}

$(function() {
    $('[data-toggle="chart"]').each(function (i, el) {
        var type = $(el).data('type');
        var admin = $(el).data('admin');
        var name = $(el).data('name');
        var id = $(el).data('id');
        var load = $(el).data('load');

        if (load) {
            setToolbarParams(name);
            setTableEvents(name);

            widgets[name] = $(el);
        } else {
            var url = path + '/api/widget/' + admin + '/' + name + '/';
            if (id != '') {
                url += id + '/';
            }

            $.ajax({
                type: 'get',
                url: url,
                dataType: 'json',
                success: function(data) {
                    switch (type) {
                        case 'series':
                            renderSeriesChart(name, el, data.query, data.results);
                            break;
                        case 'groups':
                            renderGroupsChart(name, el, data.query, data.results);
                            break;
                        case 'sankey':
                            renderSankeyDiagram(name, el, data.query, data.results);
                            break;
                        case 'geomap':
                            renderGeoMap(name, el, data.query, data.results);
                            break;
                        case 'content':
                            renderContent(name, el, data.query, data.results);
                            break;
                    }

                    $('#' + name + ' .overlay').removeClass('d-flex');
                },
                beforeSend: function () {
                    $('#' + name + ' .overlay').addClass('d-flex');
                },
                error: function () {
                    $('#' + name + ' .overlay').removeClass('d-flex');
                }
            });
        }
    });

    $('.filter .form-control').on('change', filterEvent);
    $('.toolbar button').on('click', toolbarEvent);
});

function renderSeriesChart(name, el, query, results) {
    var series = [];
    var yaxis = [];
    var colors = [];

    if (!query.config.height) {
        query.config.height = 320;
    }
    if (!query.config.type) {
        query.config.type = 'line';
    }
    if (!query.config.fmt) {
        query.config.fmt = '$1';
    }
    
    var i = 0;
    $.each(query.config.series, function (key, serie) {
        var data = [];
        $.each(results[key], function (x, y) {
            switch (serie.dtype) {
                case 'float':
                    y = parseFloat(y);
                    break;
                default:
                    y = parseInt(y);
            }

            data.push({
                x: x,
                y: y
            });
        });

        series.push({
            name: serie.name,
            type: serie.type ? serie.type : query.config.type,
            data: data
        });

        if ($.inArray(serie.axis, Object.keys(query.config.series)) !== -1) {
            yaxis.push({
                seriesName: query.config.series[serie.axis]['name'],
                show: false
            });
        } else {
            yaxis.push({
                seriesName: serie.name,
                show: serie.axis != 'none',
                opposite: serie.axis == 'right',
                min: 0,
                forceNiceScale: true,
                labels: {
                    formatter: function(val, index) {
                        return formatNumber(val).replace(/^(.*)$/g, query.config.fmt);
                    }
                }
            });
        }

        if ($.inArray(serie.color, Object.keys(palette)) !== -1) {
            colors.push(palette[serie.color]);
        } else if (serie.color) {
            colors.push(serie.color);
        } else {
            var len = Object.keys(palette).length;
            var div = Math.floor(i / len);
            var i_ = div > 0 ? i - (div * len) : i;
            colors.push(Object.values(palette)[i_]);
        }
        
        i++;
    });

    var options = {
        chart: {
            height: query.config.height,
            type: query.config.type,
            toolbar: {
                show: query.config.toolbar ? false : true
            },
            zoom: {
                enabled: query.config.toolbar ? false : true
            },
            stacked: query.config.stacked ? true : false
        },
        dataLabels: {
            enabled: false
        },
        colors: colors,
        stroke: {
            width: 4,
            curve: 'smooth'
        },
        markers: {
            size: 0
        },
        series: series,
        xaxis: {
            type: 'datetime'
        },
        yaxis: yaxis,
        tooltip: {
            enabled: true,
            x: {
                format: 'yyyy/MM/dd'
            },
            y: {
                formatter: function(val, opts) {
                    return formatNumber(val).replace(/^(.*)$/g, query.config.fmt);
                }
            }
        },
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
            verticalAlign: 'middle',
            floating: false,
            fontSize: '14px',
            offsetX: 0,
            offsetY: 5
        },
        responsive: [{
            breakpoint: 600,
            options: {
                chart: {
                    toolbar: {
                        show: false
                    }
                },
                legend: {
                    show: false
                }
            }
        }]
    };

    var widget = new ApexCharts(el, options);
    widget.render();
    widgets[name] = widget;
    params[name] = query['params'];
}

function updateSeriesChart(name, widget, query, results) {
    var series = [];

    $.each(query.config.series, function (key, serie) {
        var d = [];
        $.each(results[key], function (x, y) {
            switch (serie.dtype) {
                case 'float':
                    y = parseFloat(y);
                    break;
                default:
                    y = parseInt(y);
            }

            d.push({
                x: x,
                y: y
            });
        });

        series.push({
            name: serie.name,
            type: serie.type ? serie.type : query.config.type,
            data: d
        });
    });

    widget.updateOptions({
        series: series
    });
}

function renderGroupsChart(name, el, query, results) {
    var series = [];
    var labels = [];
    var yaxis = [];
    var colors = [];

    if (!query.config.height) {
        query.config.height = 320;
    }
    if (!query.config.type) {
        query.config.type = 'pie';
    }
    if (!query.config.fmt) {
        query.config.fmt = '$1';
    }
    if (!query.config.horizontal) {
        query.config.horizontal = false;
    }

    $.each(query.config.series, function (key, serie) {
        if (query.config.type == 'bar' || query.config.type == 'line') {
            var data = [];
            $.each(results[key], function (x, y) {
                switch (serie.dtype) {
                    case 'float':
                        y = parseFloat(y);
                        break;
                    default:
                        y = parseInt(y);
                }

                data.push({
                    x: x,
                    y: y
                });
                labels.push(x);
            });

            series.push({
                name: serie.name,
                type: serie.type ? serie.type : query.config.type,
                data: data
            });

            if ($.inArray(serie.axis, Object.keys(query.config.series)) !== -1) {
                yaxis.push({
                    seriesName: query.config.series[serie.axis]['name'],
                    show: false
                });
            } else {
                yaxis.push({
                    seriesName: serie.name,
                    show: serie.axis != 'none',
                    opposite: serie.axis == 'right',
                    min: 0,
                    forceNiceScale: true,
                    labels: {
                        formatter: function(val, index) {
                            return formatNumber(val).replace(/^(.*)$/g, query.config.fmt);
                        }
                    }
                });
            }
        } else if (query.config.type == 'histogram') {
            var data = [];
            $.each(results[key], function (x, y) {
                switch (serie.dtype) {
                    case 'float':
                        x = parseFloat(x);
                        y = parseFloat(y);
                        break;
                    default:
                        x = parseInt(x);
                        y = parseInt(y);
                }

                for (var i = 0; i < y; i++) {
                    data.push(x);
                }
            });
            series.push({
                name: serie.name,
                data: data
            });
        } else {
            $.each(results[key], function (x, y) {
                switch (serie.dtype) {
                    case 'float':
                        y = parseFloat(y);
                        break;
                    default:
                        y = parseInt(y);
                }

                series.push(y);
                labels.push(x);
            });
        }

        for (var i = 0; i < series.length; i++) {
            if (serie.color && $.inArray(serie.color[i], Object.keys(palette)) !== -1) {
                colors.push(palette[serie.color[i]]);
            } else if (serie.color && serie.color[i]) {
                colors.push(serie.color[i]);
            } else {
                var len = Object.keys(palette).length;
                var div = Math.floor(i / len);
                var i_ = div > 0 ? i - (div * len) : i;
                colors.push(Object.values(palette)[i_]);
            }
        }
    });

    var plotOptions = {};

    if (query.config.type == 'bar') {
        $.extend(plotOptions, {
            bar: {
                horizontal: query.config.horizontal ? true : false,
                borderRadius: 5,
                dataLabels: {
                    position: 'top'
                }
            }
        });
    } else if (query.config.type == 'pie') {
        $.extend(plotOptions, {
            pie: {
                dataLabels: {
                    minAngleToShowLabel: 18
                }
            }
        });
    } else if (query.config.type == 'donut') {
        $.extend(plotOptions, {
            pie: {
                dataLabels: {
                    minAngleToShowLabel: 18
                },
                donut: {
                    labels: {
                        show: true,
                        value: {
                            formatter: function (val) {
                                return formatNumber(val).replace(/^(.*)$/g, query.config.fmt);
                            }
                        },
                        total: {
                            show: true,
                            showAlways: true,
                            label: 'Total',
                            color: textColor,
                            formatter: function (w) {
                                return formatNumber(
                                    w.globals.seriesTotals.reduce((a, b) => {
                                        return a + b
                                    }, 0)
                                ).replace(/^(.*)$/g, query.config.fmt);
                            }
                        }
                    }
                }
            }
        });
    }

    var dataLabels = {
        enabled: query.config.labels ? true : false
    };

    if (query.config.type == 'bar') {
        $.extend(dataLabels, {
            offsetY: -20,
            style: {
                fontWeight: 400,
                colors: [textColor]
            },
            formatter: function(val, opts) {
                return formatNumber(val).replace(/^(.*)$/g, query.config.fmt);
            }
        });
    }

    var options = {
        chart: {
            height: query.config.height,
            type: query.config.type,
            toolbar: {
                show: query.config.toolbar ? false : true
            },
            zoom: {
                enabled: query.config.toolbar ? false : true
            },
            stacked: query.config.stacked ? true : false
        },
        plotOptions: plotOptions,
        dataLabels: dataLabels,
        series: series,
        labels: labels,
        yaxis: yaxis,
        colors: colors,
        stroke: {
            width: 4,
            curve: 'smooth'
        },
        markers: {
            size: 0
        },
        tooltip: {
            enabled: true,
            y: {
                formatter: function(val, opts) {
                    return formatNumber(val).replace(/^(.*)$/g, query.config.fmt);
                }
            }
        },
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
            verticalAlign: 'middle',
            floating: false,
            fontSize: '14px',
            offsetX: 0,
            offsetY: 5
        },
        responsive: [{
            breakpoint: 600,
            options: {
                chart: {
                    height: 240
                },
                legend: {
                    show: false
                }
            }
        }]
    };

    var widget = new ApexCharts(el, options);
    widget.render();
    widgets[name] = widget;
    params[name] = query['params'];
}

function updateGroupsChart(name, widget, query, results) {
    var series = [];
    var labels = [];

    $.each(query.config.series, function (key, serie) {
        if (query.config.type == 'bar' || query.config.type == 'line') {
            var d = [];
            $.each(results[key], function (x, y) {
                switch (serie.dtype) {
                    case 'float':
                        y = parseFloat(y);
                        break;
                    default:
                        y = parseInt(y);
                }

                d.push({
                    x: x,
                    y: y
                });
                labels.push(x);
            });

            series.push({
                name: serie.name,
                type: serie.type ? serie.type : query.config.type,
                data: d
            });
        } else if (query.config.type == 'histogram') {
            var d = [];
            $.each(results[key], function (x, y) {
                switch (serie.dtype) {
                    case 'float':
                        x = parseFloat(x);
                        y = parseFloat(y);
                        break;
                    default:
                        x = parseInt(x);
                        y = parseInt(y);
                }

                for (var i = 0; i < y; i++) {
                    d.push(x);
                }
            });
            series.push({
                name: serie.name,
                data: d
            });
        } else {
            $.each(results[key], function (x, y) {
                switch (serie.dtype) {
                    case 'float':
                        y = parseFloat(y);
                        break;
                    default:
                        y = parseInt(y);
                }

                series.push(y);
                labels.push(x);
            });
        }
    });

    widget.updateOptions({
        series: series,
        labels: labels
    });
}

function renderSankeyDiagram(name, el, query, results) {
    var nodes = [];
    var links = [];
    
    $.each(results.nodes, function (i, node) {
        nodes.push({
            label: node
        });
    });

    $.each(results.links, function (i, link) {
        links.push({
            from: link.source,
            to: link.target,
            value: parseInt(link.total)
        });
    });

    var options = {
        type: 'sankey',
        renderAt: el,
        width: '100%',
        height: '100%',
        dataFormat: 'json',
        dataSource: {
            chart: {
                theme: 'fusion',
                orientation: 'horizontal',
                showLegend: 0,
                linkalpha: 30,
                linkhoveralpha: 60,
                nodelabelposition: 'right',
                linkcolor: 'source',
                chartBottomMargin: 30
            },
            nodes: nodes,
            links: links
        }
    };
    
    var widget = new FusionCharts(options);
    widget.render();
    widgets[name] = widget;
    params[name] = query['params'];
}

function updateSankeyChart(name, widget, query, results) {
    var nodes = [];
    var links = [];
    
    $.each(results.nodes, function (i, node) {
        nodes.push({
            label: node
        });
    });

    $.each(results.links, function (i, link) {
        links.push({
            from: link.source,
            to: link.target,
            value: parseInt(link.total)
        });
    });

    widget.setJSONData({
        chart: widget.getJSONData().chart,
        nodes: nodes,
        links: links
    });
}

function renderGeoMap(name, el, query, results) {
    var markers = [];
    var clusterer;

    if (!query.config.mask) {
        query.config.mask = '{name}';
    }

    $(el).addClass('map');

    var map = new google.maps.Map(el, {
        zoom: query.config.zoom,
        center: {
            lat: parseFloat(query.config.center[0]),
            lng: parseFloat(query.config.center[1])
        },
        mapTypeId: query.config.layer,
        gestureHandling: 'cooperative',
        streetViewControl: false
    });

    var mask = query.config.mask.replace('{path}', path);

    $.each(results['pins'], function (i, loc) {
        var content = mask.replace(/\{(\w+)\}/g, function(match, key, offset, s) {
            return $.inArray(key, Object.keys(loc)) !== -1 ? loc[key] : '';
        });
        var info = new google.maps.InfoWindow({
            content: content
        });

        var marker = new google.maps.Marker({
            position: {
                lat: parseFloat(loc['lat']),
                lng: parseFloat(loc['lng'])
            },
            map: !query.config.clusters ? map : null,
            title: loc['name']
        });

        marker.addListener('click', function() {
            info.open(map, marker);
        });

        markers.push(marker);
    });

    if (query.config.clusters) {
        clusterer = new MarkerClusterer(map, markers, {
            imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
        });
    }

    widgets[name] = map;
    params[name] = query['params'];
    maps[name] = {
        markers: markers,
        clusterer: clusterer
    };
}

function updateGeoMap(name, map, query, results) {
    if (query.config.clusters) {
        maps[name]['clusterer'].setMap(null);
    }

    for (var i = 0; i < maps[name]['markers'].length; i++) {
        maps[name]['markers'][i].setMap(null);
    }

    var markers = [];
    var clusterer;

    if (!query.config.mask) {
        query.config.mask = '{name}';
    }

    map.setOptions({
        zoom: query.config.zoom,
        center: {
            lat: parseFloat(query.config.center[0]),
            lng: parseFloat(query.config.center[1])
        }
    });

    var mask = query.config.mask.replace('{path}', path);

    $.each(results['pins'], function (i, loc) {
        var content = mask.replace(/\{(\w+)\}/g, function(match, key, offset, s) {
            return $.inArray(key, Object.keys(loc)) !== -1 ? loc[key] : '';
        });
        var info = new google.maps.InfoWindow({
            content: content
        });

        var marker = new google.maps.Marker({
            position: {
                lat: parseFloat(loc['lat']),
                lng: parseFloat(loc['lng'])
            },
            map: !query.config.clusters ? map : null,
            title: loc['name']
        });

        marker.addListener('click', function() {
            info.open(map, marker);
        });

        markers.push(marker);
    });

    if (query.config.clusters) {
        clusterer = new MarkerClusterer(map, markers, {
            imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
        });
    }

    maps[name] = {
        markers: markers,
        clusterer: clusterer
    };
}

function renderContent(name, el, query, results) {
    var widget = $(el);

    widget.html(results);
    setTableEvents(name);

    widgets[name] = widget;
    params[name] = query['params'];
}

function updateContent(name, widget, query, results) {
    widget.html(results);
    setTableEvents(name);
}

function filterEvent(e) {
    var param = $(e.target).attr('name');
    var value = $(e.target).val();

    if ($.isArray(value)) {
        param = param.replace('[]', '');
        value = value.join(',');
    }

    $('[data-toggle="chart"]').each(function (i, el) {
        var admin = $(el).data('admin');
        var name = $(el).data('name');

        $('#' + name + ' .overlay').addClass('d-flex');

        var widget = widgets[name];
        params[name][param] = value;

        $.ajax({
            type: 'get',
            url: path + '/api/widget/' + admin + '/' + name + '/',
            data: $.param(params[name]),
            dataType: 'json',
            success: function(data) {
                switch (data.query.type) {
                    case 'series':
                        updateSeriesChart(name, widget, data.query, data.results);
                        break;
                    case 'groups':
                        updateGroupsChart(name, widget, data.query, data.results);
                        break;
                    case 'sankey':
                        updateSankeyDiagram(name, widget, data.query, data.results);
                        break;
                    case 'geomap':
                        updateGeoMap(name, widget, data.query, data.results);
                        break;
                    case 'content':
                        updateContent(name, widget, data.query, data.results);
                        break;
                }

                $('#' + name + ' .overlay').removeClass('d-flex');
            },
            beforeSend: function() {
                $('#' + name + ' .overlay').addClass('d-flex');
            },
            error: function () {
                $('#' + name + ' .overlay').removeClass('d-flex');
            }
        });
    });
}

function toolbarEvent(e) {
    var admin = $(e.target).data('admin');
    var name = $(e.target).data('name');
    var param = $(e.target).data('param');
    var value = $(e.target).data('value');

    $('.toolbar button[data-name="' + name + '"][data-param="' + param + '"]').each(function (i, el) {
        $(el).removeClass('active');
    });
    $(e.target).addClass('active');

    $('#' + name + ' .overlay').addClass('d-flex');

    var widget = widgets[name];
    params[name][param] = value;

    if (param == 'actions') {
        if (value == 'export') {
            $.ajax({
                type: 'get',
                url: path + '/api/widget/' + admin + '/' + name + '/csv/',
                data: $.param(params[name]),
                dataType: 'json',
                success: function(data) {
                    window.location = path + '/export/?name=' + data.filename;

                    $('#' + name + ' .overlay').removeClass('d-flex');
                },
                beforeSend: function() {
                    $('#' + name + ' .overlay').addClass('d-flex');
                },
                error: function () {
                    $('#' + name + ' .overlay').removeClass('d-flex');
                }
            });
        }
    } else if ($('div[data-name="' + name + '"]').data('load')) {
        window.location = path + '/admin/s/' + admin + '/?' + $.param(params[name]);
    } else {
        $.ajax({
            type: 'get',
            url: path + '/api/widget/' + admin + '/' + name + '/',
            data: $.param(params[name]),
            dataType: 'json',
            success: function(data) {
                switch (data.query.type) {
                    case 'series':
                        updateSeriesChart(name, widget, data.query, data.results);
                        break;
                    case 'groups':
                        updateGroupsChart(name, widget, data.query, data.results);
                        break;
                    case 'sankey':
                        updateSankeyDiagram(name, widget, data.query, data.results);
                        break;
                    case 'geomap':
                        updateGeoMap(name, widget, data.query, data.results);
                        break;
                    case 'content':
                        updateContent(name, widget, data.query, data.results);
                        break;
                }

                $('#' + name + ' .overlay').removeClass('d-flex');
            },
            beforeSend: function() {
                $('#' + name + ' .overlay').addClass('d-flex');
            },
            error: function () {
                $('#' + name + ' .overlay').removeClass('d-flex');
            }
        });
    }
}

function setToolbarParams(name) {
    params[name] = {};

    $('.toolbar button[data-name="' + name + '"]').each(function (i, el) {
        var param = $(el).data('param');
        var value = $(el).data('value');
        
        if ($(el).hasClass('active')) {
            if ($.inArray(param, params[name]) === -1) {
                params[name][param] = {};
            }
            params[name][param] = value;
        }
    });
}

function setTableEvents(name) {
    $('div[data-name="' + name + '"] .table tbody tr').on('click', function (e) {
        if ($(this).hasClass('table-success')) {
            $(this).removeClass('table-success');
        } else {
            $(this).addClass('table-success').siblings().removeClass('table-success');
        }
    });

    $('div[data-name="' + name + '"] .table tbody tr[data-href]').on('dblclick', function (e) {
        window.location = $(this).data('href');
    });

    $('div[data-name="' + name + '"] .table thead tr th').on('click', function (e) {
        var table = $(this).closest('.table-sm');
        var tbody = $(table).children('tbody');
        var column = $(this).attr('title');
        var index = $(this).index() - 1;

        // Sort rows by column value (descending)
        if ($(table).data('sortable')) {
            var rows = $(tbody).children('tr').get();

            rows.sort(function (a, b) {
                var va = parseFloat($(a).children('td').eq(index).text());
                var vb = parseFloat($(b).children('td').eq(index).text());

                return vb - va; // Descending
            });

            $(tbody).empty();
            $.each(rows, function(i, el) {
                $(tbody).append(el);
            });
        }
    });
}

function formatNumber(n, dec, dec_force, fmt) {
    n = parseFloat(n);
    dec = typeof dec !== 'undefined' && !isNaN(parseInt(dec)) ? dec : number.decimals;

    var s = n.toFixed(dec);

    var parts = s.split('.');
    var s_int = parts.length > 0 ? Math.abs(parseInt(parts[0])).toString() : '0';
    var s_dec = parts.length > 1 ? parts[1].replace(/0+$/g, '') : '';
    var mod = s_int.length > 3 ? s_int.length % 3 : 0;

    var r = n < 0 ? '-' : '';

    r += mod > 0 ? s_int.substr(0, mod) + number.thou_sep : '';
    r += s_int.substr(mod).replace(/(\d{3})(?=\d)/g, '$1' + number.thou_sep);

    if (dec_force && s_dec.length < dec) {
        s_dec = s_dec.padEnd(dec, '0');
    }

    if (s_dec != '') {
        r += number.dec_point + s_dec;
    }

    return r
}
