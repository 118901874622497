var galleries = {};
var draggables = {};

$(function() {
    $(window).on('scroll', function (e) {
        var el = $('.topnav');

        if (el.length > 0 && window.innerWidth > 768) {
            var fixPosition = el.position().top;
            var curOffset = window.pageYOffset;

            if (curOffset > fixPosition) {
                el.addClass('fixed');
            } else {
                el.removeClass('fixed');
            }
        }
    });

    $('.nav-block .nav-link').on('click', function (e) {
        e.preventDefault();

        var target = $("div[id='" + $(this).data('target') + "']");
        var topnav = $('.topnav');

        var sub = 50;
        if (topnav.length > 0) {
            sub += topnav.height();
        }

        $('html, body').animate({
            scrollTop: target.offset().top - sub
        });
    });

    $('[data-bs-toggle="tooltip"]').tooltip();
    
    $('[data-bs-toggle="popover"]').popover({
        trigger: 'focus',
        container: 'body',
        html: true,
        placement: 'left'
    });

    $('#details-modal').on('show.bs.modal', function (e) {
        var src = $(e.relatedTarget);
        var tgt = $(e.target);

        var title = src.data('bs-title');
        if (title) {
            tgt.find('.modal-title').html(title);
        }

        var type = src.data('bs-type');
        var content = src.data('bs-content');
        if (content) {
            if (type) {
                tgt.find('.modal-body').addClass('p-0').append(
                    $('<div>').attr({
                        class: type == 'iframe' || type == 'embed' ? 'ratio ratio-16x9' : undefined
                    }).append(
                        $('<' + type + '>').attr({
                            src: content,
                            width: '100%',
                            allow: type == 'iframe' ? 'autoplay; fullscreen' : undefined
                        })
                    )
                );
            } else {
                tgt.find('.modal-body').html(content);
            }
        }
    });

    $('#details-modal').on('hidden.bs.modal', function (e) {
        $(this).find('iframe, embed').removeAttr('src');
        $(this).find('.modal-body').html('');
    });

    $('.gallery, .assets').each(function (i, el) {
        var name = $(el).data('name');
        var items = $(el).find('.gallery-item');

        if (items.length > 0) {
            galleries[name] = lightGallery(el, {
                plugins: [lgFullscreen, lgThumbnail, lgZoom, lgVideo],
                selector: '.gallery-item',
                speed: 500,
                mode: 'lg-fade',
                exThumbImage: 'data-thumbnail',
                autoplayVideoOnSlide: true
            });

            var container = $(el).find('tbody').length > 0 ? $(el).find('tbody')[0] : el;
            draggables[name] = dragula([container], {
                revertOnSpill: true
            }).on('drop', function (elem, target, source, sibling) {
                var elemId = $(elem).data('id');
                var siblingId = $(sibling).data('id');

                var overlay = $(target).parents('.card').find('.overlay');
                var data = {
                    asset_id: elemId,
                    next_id: siblingId
                };

                $.ajax({
                    type: 'post',
                    url: path + '/api/uploader/sort/',
                    data: $.param(data),
                    dataType: 'json',
                    success: function(data) {
                        galleries[name].refresh();

                        overlay.removeClass('d-flex');
                    },
                    beforeSend: function() {
                        overlay.addClass('d-flex');
                    },
                    error: function () {
                        overlay.removeClass('d-flex');
                    }
                });
            });
        }
    });

    $('.gallery-open').on('click', function (e) {
        e.preventDefault();

        var name = $(this).parents('.card').find('.gallery').data('name');
        galleries[name].openGallery();
    });
});

function exportDashboard(dashboard) {
    var paramsPos = window.location.href.indexOf('?') + 1;
    var data = window.location.href.substr(paramsPos);

    $.ajax({
        type: 'get',
        url: path + '/api/dashboard/' + dashboard + '/',
        data: data,
        dataType: 'json',
        success: function(data) {
            $('#dialog').dialog('close');
            window.location = path + '/export/?name=' + data.filename;
        },
        beforeSend: function() {
            $('#dialog').dialog({
                modal: true,
                title: 'Loading...',
                open: function() {
                    $(this).html('<div class="loading">&nbsp;</div>');
                }
            });
        }
    });
}

function scrollUp() {
    $('html, body').animate({
        scrollTop: 0
    });
}

function scrollDown() {
    $('html, body').animate({
        scrollTop: $(document).height()
    });
}

function titleCase(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function strDiff(item, time) {
    var secs = time - item;
    
    var days = Math.floor(secs / 60 / 60 / 24);
    var hours = Math.floor((secs - (days * 60 * 60 * 24)) / 60 / 60);
    var mins = Math.floor((secs - (days * 60 * 60 * 24) - (hours * 60 * 60)) / 60);

    var sDiff = '';
    if (days > 0) {
        sDiff += (days > 1) ? days + ' days' : '1 day';
    } else if (hours > 0) {
        sDiff += (hours > 1) ? hours + ' hours' : '1 hour';
    } else if (mins > 0) {
        sDiff += (mins > 1) ? mins + ' mins' : '1 min';
    } else {
        sDiff = 'seconds';
    }
    sDiff += ' ago';

    return sDiff;
}

function checkNotifications() {
    if (!Notification) {
        alert('Desktop notifications not available in your browser. Try Chrome.');
        
        return;
    }

    if (Notification.permission !== 'granted') {
        Notification.requestPermission();
    }
}

function sendNotification(title, icon, body, onclick) {
    if (Notification.permission !== 'granted') {
        Notification.requestPermission();
        
        return;
    }

    var notif = new Notification(title, {
        icon: icon,
        body: body
    });

    notif.onclick = onclick;
}
